import React, {useState} from "react"
import PropTypes from "prop-types"
import { getIncidentTypes, getSources } from "../../services/api"
import Loading from "../common/loading"

const Filters = ({setFilterCallback, closeCallback, filters, isNDD}) => {
	const [activeFilters, setActiveFilters] = useState([])
	const [incidentTypes, setIncidentTypes] = useState({})
	const [sources, setSources] = useState([])
	const [allTypesSelected, setAllTypesSelected] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState([])

	
	let activateFilter = (filter) => {
		let newFilters = [...activeFilters]
		if(newFilters.includes(filter)) {
			// only close if there are no filters selected
			//if(filters[filter].length == 0) {
				newFilters = newFilters.filter(f => f != filter)
			//}
		} else {
			newFilters.push(filter)
		}

		setActiveFilters(newFilters)
	}

	let setCatrgoryFilter = (category) => {
		if(selectedCategory.includes(category)){
			setSelectedCategory(selectedCategory.filter(x => x !== category))
			setFilterCallback('type', filters.type
																.filter(x => !incidentTypes[category].map(y => y.name).includes(x)))
		}else{
			setSelectedCategory([...selectedCategory, category])
			//create array of filters with with added category.
			//also checks for duplicates
			let uniqueFilters = filters.type
													.concat(
														incidentTypes[category]
														.map(y => y.name)//get names
														.filter(x => !filters.type.includes(x))//filter out duplicates
													)
			
			setFilterCallback('type', uniqueFilters)
		}
	}

	const setAllFilters = () => {
		setAllTypesSelected(!allTypesSelected)
		if(!allTypesSelected){
			let types = getAllFiltersArray()
			setFilterCallback('type', types)
			setSelectedCategory(Object.keys(incidentTypes))
		}else{
			setFilterCallback('type', [])
			setSelectedCategory([])
		}
	}

	const getAllFiltersArray = () => {
		let allFilters = [];
		for(const category of Object.values(incidentTypes)){
			for(const type of category){
				allFilters.push(type.name)
			}
		}
		return allFilters
	}

	if(!filters.type)
		filters.type = []
	if(!filters.impact)
		filters.impact = []
	if(!filters.status)
		filters.status = []
	if(!filters.source)
		filters.source = []

	let impactFilters = []
	let typeFilters = []
	let statusFilters = []
	let sourceFilters = []

	if(activeFilters.includes('impact')) {
		impactFilters = (
			<ul>
				<li onClick={(e) => {setFilterCallback('impact', 'high') }} className={filters.impact.includes('high') ? 'active' : ''}>
					<span className="impact-box high"></span> <span className="impact-label">High</span>
				</li>
				<li onClick={(e) => {setFilterCallback('impact', 'medium') }} className={filters.impact.includes('medium') ? 'active' : ''}>
					<span className="impact-box medium"></span> <span className="impact-label">Medium</span>
				</li>
				<li onClick={(e) => {setFilterCallback('impact', 'low') }} className={filters.impact.includes('low') ? 'active' : ''}>
					<span className="impact-box low"></span> <span className="impact-label">Low</span>
				</li>
			</ul>
		)
	}
	if(activeFilters.includes('type')) {
		getIncidentTypes(true).then(types => {
			setIncidentTypes(types)
		})
		if(Object.keys(incidentTypes).length > 0) {
			typeFilters = (
				<ul>
					<li key={0} onClick={(e) => { setAllFilters() }} className={allTypesSelected ? 'active' : ''}>- {allTypesSelected ? 'De-s' : 'S'}elect all -</li>
					{Object.keys(incidentTypes).map(category => 
					<div key={category}>
						<li className={"category "+(selectedCategory.includes(category) ? 'active': '')} onClick={(e) => {setCatrgoryFilter(category)}}>{category}</li>
						{incidentTypes[category].map( type => 
							<li key={type.name} onClick={(e) => {setFilterCallback('type', type.name) }} className={filters.type.includes(type.name) ? 'active' : ''}>
								{type.name}
							</li>
						)}
					</div>
					)}
				</ul>
			)
		} else {
			typeFilters = (
				<Loading />
			)
		}
	}
	if(activeFilters.includes('status')) {
		statusFilters = (
			<ul>
				<li onClick={(e) => {setFilterCallback('status', 'current') }} className={filters.status.includes('current') ? 'active' : ''}>
					Current
				</li>
				<li onClick={(e) => {setFilterCallback('status', 'predicted') }} className={filters.status.includes('predicted') ? 'active' : ''}>
					Predicted
				</li>
			</ul>
		)
	}
	if(activeFilters.includes('source')) {
		getSources().then(apiSources => {
			setSources(apiSources)
		})
		if(sources != null) {
			sourceFilters = (
				<ul>
					{sources.map(source => {
						if((isNDD === true && (source.id == 8 || source.id == 35 )) || !isNDD){
							return (
								<li key={source.name} onClick={(e) => {setFilterCallback('source', source.name) }} className={filters.source.includes(source.name) ? 'active' : ''}>
									{source.name}
								</li>
							)
						}
					})}
				</ul>
			)
		} else {
			sourceFilters = (
				<Loading />
			)
		}
	}

  return (
	<div className="other-filters filter-list">
		<h4>Filters</h4>
		<a href="#" className="close-button" onClick={closeCallback}>X</a>
		<div className="filter-names">
			<ul>
				<li className={activeFilters.includes('impact') ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateFilter('impact') }}>Impact</span>
					{impactFilters}
				</li>
				<li className={activeFilters.includes('type') ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateFilter('type') }}>Incident Type</span>
					{typeFilters}
				</li>
				<li className={activeFilters.includes('status') ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateFilter('status') }}>Incident Status</span>
					{statusFilters}
				</li>
				<li className={activeFilters.includes('source') ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateFilter('source') }}>Source</span>
					{sourceFilters}
				</li>
			</ul>
		</div>

	</div>
  )
}

Filters.propTypes = {
	setFilterCallback: PropTypes.func.isRequired,
	closeCallback: PropTypes.func,
	filters: PropTypes.object.isRequired,
	isNDD: PropTypes.bool,
	countries: PropTypes.array
}

export default Filters