import React, {useEffect, useState} from "react"

import { getSubscriptions, updateSubscriptions, Unsubscribe } from "../../services/api";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Loading from "../../components/common/loading"

import Countries from "../../components/map/countries"

import Filters from "../../components/map/filters"
import "./[id].scss"
import { isAdmin } from "../../services/auth";
import { navigate } from "gatsby";


const ManageAlerts = (pathdata) => {
  const [id, setId] = useState(0);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [frequency, setFrequency] = useState("");
  const [filters, setFilters] = useState({});
  const [hash, setHash] = useState("");
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [save_laoding, setSaveLoading] = useState(false);

  // activate new filter
  const toggleFilter = (key, filter) => {
    if(key == 'reset') {
      setFilters({})
      setCountriesSelected([])
      return;
    }
    let newFilters = {...filters}

    if (typeof(filter) === 'object'){
      newFilters[key] = filter
    }else{
      if(newFilters[key] && newFilters[key].includes(filter)) {
        newFilters[key] = newFilters[key].filter(f => f != filter)
      } else { 
        if(!newFilters[key]) {
          newFilters[key] = []
        }
        newFilters[key].push(filter)
      }
    }

    setFilters(newFilters)
  }


  useEffect(() => {
    getSubscriptions(parseInt(pathdata.id)).then(response =>{
      setEmail(response[0].email)
      setType(response[0].type)
      setFrequency(response[0].frequency)
      response[0].filters.type = response[0].filters.incidentType
      setFilters(response[0].filters)
      setCountriesSelected(response[0].countryCodes)
      setId(response[0].id);
      setHash(response[0].hash)
    })
  },[])

  if (!isAdmin() && typeof window != 'undefined'){
    navigate("/404")
    return (<></>)
  }

  const updateEmail = (event) => {
		setEmail(event.target.value)
	}

  const updateFrequency = (event) => {
    setFrequency(event.target.value)
  }

  const sendForm = (event) => {
    let myFilters = filters;
    myFilters["incidentType"] = filters["type"];
    let data = {
      id: id,
      email: email,
      frequency: frequency,
      filters: filters,
      countryCodes: countriesSelected
    }
    setSaveLoading(true);
    updateSubscriptions(data).then(()=>{
      setSaveLoading(false);      
    })
  }


  const unsubscribe = (id, hash) => {
    Unsubscribe(id, hash).then( response=>{
      if(response.Success){
        this.setState((prevState)=>({
          subscriptions : prevState.subscriptions.filter(x => x.id !== id)
        }))
        window.location.href = "/manage-alerts";
      }
    })
  }

  const showForm = () =>{
    if (email === ""){
      return <Loading/>
    }
    return (
      <div className="edit-form">
        <div className="left-wrapper">
          <span className="unsubscribe" onClick={() =>unsubscribe(id, hash)}>Unsubscribe</span>
          <h4>Info</h4>
          <div className="form-field">
            <span>E-mail</span> 
            <input type="email" value={email} onChange={updateEmail.bind(this)}/>
          </div>
          <div className="form-field">
            <span>Frequency</span>
            <select value={frequency} onChange={updateFrequency.bind(this)}>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div className="form-field"><span className="label">Type</span><span className="type">{type}</span></div>
        </div>
        <div className="filter-menu">
          <div className="filters-wrapper">
            <Filters
              setFilterCallback={ toggleFilter }
              filters={filters}
              closeCallback={ null }
            />
          </div>
          <div className="countries-wrapper">
            <Countries countries={countriesSelected} 
            setCountryCallback={ 
              (country) => {
                let newFilters = {...filters}
                
                // if (Array.isArray(country) == false) {
                // 	country = [country]
                // }
                // for(let key in country) {
                // 	let _country = country[key]
                //
                // 	if(newFilters.countries) {
                // 		if(newFilters.countries.includes(_country)) {
                // 			newFilters.countries = newFilters.countries.filter(c => c != _country)
                // 		} else {
                // 			newFilters.countries.push(_country)
                // 		}
                // 	} else {
                    newFilters.countries = country
                // 	}
                // }
                
                setFilters(newFilters)
              }}
              /> 
          </div>
          {save_laoding ? <Loading className="button-loading" /> : <button className="button" onClick={sendForm}>Save</button>}
        </div>
      </div>
      )
    }
  

	return (
    <Layout>
      <Seo title="PostNL Early Warning System Map" />
      <div className="container">
        <h2>Manage alerts</h2>
        {showForm()}
      </div>
    </Layout>
  )
}
  
export default ManageAlerts