import React, {useState} from "react"
import PropTypes from "prop-types"

const Countries = ({countryController, closeCallback, countries}) => {
	if(!countries) {
		countries = []
	}
	const [continent, setContinent] = useState(null)
	const [selectedAll, setSelectedAll] = useState([])

	const selectContinent = (continent) =>{
		if(continent != null){
			countryController.setAll(continent)
			setSelectedAll( [...selectedAll, continent] )
		}
	}

	const deselectContinent = (continent) => {
		if(continent != null){
			countryController.setNone(continent)
			const selectedAllCopy = selectedAll.filter(c => c != continent)
			setSelectedAll(selectedAllCopy)	
		}
	}

	const activateContinent = (newContinent) => {
		if (continent == newContinent) {
			setContinent(null)
		} else {
			setContinent(newContinent)
		}
	};


	let countryList = []
	
	let continentCountries = countryController.getContinentCountries(continent);
	if(continentCountries !== null) {
		if(selectedAll.includes(continent)) {
			countryList.push(
				<li key={0} onClick={() => { deselectContinent(continent) }}>- de-select all -</li>
			);
		} else {
			countryList.push(
				<li key={0} onClick={() => { selectContinent(continent) }}>- select all -</li>
			);
		}
		for(const key in continentCountries) {
			let country = continentCountries[key]
	
			let className = ""
			if( countries && countries.includes(country.code) ) {
				className = "active"
			}
			countryList.push(
					<li key={key+1} className={className} onClick={countryController.addCountry.bind(this,country.code)}>
						{country.name}
					</li>
				)
		}
		countryList = (<ul className="countries">{ countryList }</ul>)
	}
	
	let close = (<></>);
	if(closeCallback != false) {
		close = (<a href="#" className="close-button" onClick={closeCallback}>X</a>)
	}
  return (
	<div className="country-select filter-list">
		<h4>Select a country</h4>
		{close}
		<div className="continents">
			<ul>
				<li className={continent == 'Africa' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('Africa') }}>Africa</span>
					{continent == 'Africa' ? countryList : []}
				</li>
				<li className={continent == 'Antartica' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('Antartica') }}>Antartica</span>
				{continent == 'Antartica' ? countryList : []}
				</li>
				<li className={continent == 'Asia' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('Asia') }}>Asia and Middle East</span>
				{continent == 'Asia' ? countryList : []}
				</li>
				<li className={continent == 'Europe' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('Europe') }}>Europe</span>
				{continent == 'Europe' ? countryList : []}
				</li>
				<li className={continent == 'North America' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('North America') }}>North and Central America</span>
				{continent == 'North America' ? countryList : []}
				</li>
				<li className={continent == 'South America' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('South America') }}>South America</span>
				{continent == 'South America' ? countryList : []}
					</li>
				<li className={continent == 'Oceania' ? 'active' : ''}><span style={{"display":"block"}} onClick={(e) => { activateContinent('Oceania') }}>Oceania</span>
				{continent == 'Oceania' ? countryList : []}
				</li>
			</ul>
		</div>

	</div>
  )
}

Countries.propTypes = {
	countryController: PropTypes.object.isRequired,
	closeCallback: PropTypes.func,
	countries: PropTypes.array
}
export default Countries